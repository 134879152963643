<template>
  <div v-if="content" class="container">
    <div class="row">
      <div class="col small">
        Na osnovu člana 436. do 445. Zakona o obligacionim poslovima, zaključuje
        se
      </div>
    </div>
    <div class="row p-5">
      <div class="col small text-center">
        <h1>UGOVOR O CESIJI</h1>
        (Ustupanje potraživanja)
      </div>
    </div>
    <div class="row">
      <div class="col small">
        zaključen dana
        {{ dateFormat(content.cession_date)  }}
        godine u Beogradu između:
      </div>
    </div>
    <div class="row">
      <div class="col small">
        1. <b>CEDENTA</b> - raniji poverilac: {{ seller.name }}
        {{ seller.addr }}
      </div>
    </div>
    <div class="row">
      <div class="col small">
        2. <b>CESIONARA</b> - novi poverilac:
        {{ content.Leasing ? content.Leasing.name : "" }}
        {{ content.Leasing ? content.Leasing.address : "" }}
        {{ content.Leasing ? content.Leasing.zip : "" }}
        {{ content.Leasing ? content.Leasing.city : "" }},
        {{ content.Leasing ? content.Leasing.country : "" }}
      </div>
    </div>
    <div class="row">
      <div class="col small">
        3. <b>CESUSA</b> - dužnik:
        {{ content.Dealer ? content.Dealer.name : "" }}
        {{ content.Dealer ? content.Dealer.address : "" }}
        {{ content.Dealer ? content.Dealer.zip : "" }}
        {{ content.Dealer ? content.Dealer.city : "" }},
        {{ content.Dealer ? content.Dealer.country : "" }}
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="small text-center fw-bold fs-5">Član 1.</div>
        <div class="small">
          Utvrđuje se da <b>CEDENT</b> ima novčana potraživanja po osnovu
          fakture broj
          <b>{{
            content.invoices ? content.invoices[content.invoices.length - 1].sef_num ?? content.invoices[content.invoices.length - 1].saop_num ?? content.invoices[content.invoices.length - 1].doc_nr : "-"
          }}</b>
          od
          <b>{{
            content.invoices
              ? dateFormat(content.invoices[content.invoices.length - 1].doc_date)
              : ""
          }}</b>
          godine od <b>CESUSA</b> u iznosu od
          <b>{{ numFormat(content.bb_neto_rsd ?? '0.00') }}</b>
          dinara.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="small text-center fw-bold fs-5">Član 2.</div>
        <div class="small">
          Ovim Ugovorom <b>CEDENT</b> ustupa <b>CESIONARU</b> svoja potraživanja
          od <b>CESUSA</b> u ukupnom iznosu od
          <b>{{ numFormat(content.bb_neto_rsd ?? '0.00') }}</b> dinara po računu
          broj
          <b>{{
            content.invoice ? content.invoices[content.invoices.length - 1].doc_nr : ""
          }}</b
          >.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="small text-center fw-bold fs-5">Član 3.</div>
        <div class="small">
          Obavezu koju <b>CESUS</b> ima prema svom dosadašnjem poveriocu,
          <b>CEDENTU</b>, u iznosu od
          <b>{{ numFormat(content.bb_neto_rsd ?? '0.00') }}</b> dinara, uplatiće
          svom novom poveriocu, <b>CESIONARU</b>.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="small text-center fw-bold fs-5">Član 4.</div>
        <div class="small">
          <b>CEDENT</b> je dužan predati isprave o dugu <b>CESIONARU</b>, kao i
          druge dokaze o ustupljenom potraživanju i sporednim pravima.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="small text-center fw-bold fs-5">Član 5.</div>
        <div class="small">
          <b>CEDENT</b> ovim ustupanjem potraživanja <b>CESIONARU</b> izvršava
          svoje obaveze po osnovu Ugovora {{ content.contract_nr }}.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="small text-center fw-bold fs-5">Član 6.</div>
        <div class="small">
          Ovaj Ugovor stupa na snagu datumom potpisivanja.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="small text-center fw-bold fs-5">Član 7.</div>
        <div class="small">
          Ovaj Ugovor je sačinjen u tri istovetna primerka, od kojih po jedan
          pripada svakom od potpisnika Ugovora.
        </div>
      </div>
    </div>

    <div class="row pt-5">
      <div class="col text-center small">
        CEDENT<br /><br />
        _______________________<br />
        {{ seller.name }}
      </div>
      <div class="col text-center small">
        CESIONAR<br /><br />
        _______________________<br />
        {{ content.Leasing ? content.Leasing.name : "" }}
      </div>
      <div class="col text-center small">
        CESUS<br /><br />
        _______________________<br />
        {{ content.Dealer ? content.Dealer.name : "" }}
      </div>
    </div>
  </div>
</template>

<script>
import dateUtils from "../../utils/date-common";
import utils from "../../utils/common";
export default {
  name: "contract",
  components: {},
  props: ["content"],
  methods: {
    dateFormat(date) {
      return dateUtils.formatDate(date);
    },
    numFormat(num) {
      return utils.toNumberFormat(num);
    },
  },
  data() {
    // return {
    //   content: {},
    // };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    seller() {
      return this.$store.state.app.seller;
    },
  },
};
</script>

<style scoped>
.container {
  /* border: 1px solid gray; */
  width: 800px;
  height: 900px;
}
.container .row > .col,
.container .row > [class^="col-"] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  /* background-color: rgba(39, 41, 43, 0.03); */
  /* border: 1px solid rgba(39, 41, 43, 0.1); */
}

.logo {
  padding: 0 !important;
}

img {
  height: 120px;
}

.sig {
  float: right;
}
tr {
  vertical-align: middle;
}
</style>