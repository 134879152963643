import http from "../utils/http-common";

class CessionsDataService {
  getAll() {
    return http.get("/cession");
  }

  get(id) {
    return http.get(`/cession/${id}`);
  }

  findFromRac(reg) {  
    return http.get(`/selling/rac/${reg}`);
  }

  findFromRacByChassis(reg) { 
    return http.get(`/selling/racCh/${reg}`);
  }

  create(data) {
    return http.post("/cession", data);
  }

  createDocument(data) {
    return http.post("/cession/doc", data);
  }

  update(id, data) {
    return http.put(`/cession/${id}`, data);
  }

  delete(id) {
    return http.delete(`/cession/${id}`);
  }
}

export default new CessionsDataService();