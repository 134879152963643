import http from "../utils/http-common";
import store from "../store";
import ConfirmDialog from "../components/widgets/ConfirmDialog.vue";

export default {
    async createDocNrFor(id, type, invoiceType, reg, api) {
        const res = await http.get(api);
        const nr = res.data['nr'] ? ('00000' + res.data['nr']).slice(-5) : '00001';
        if (reg) {
            return `${invoiceType}-${reg}-${nr}-${type}-${new Date().toISOString().slice(0, 4)}`
        } else {
            return `${invoiceType}-${nr}-${type}-${new Date().toISOString().slice(0, 4)}`
        }
    },
    async createDocNr(id, type, invoiceType, reg) {
        return await this.createDocNrFor(id, type, invoiceType, reg, "/cession-invoice/next")
        // const res = await http.get("/cession-invoice/next");
        // console.log({ nr: res.data['nr'] })
        // const nr = res.data['nr'] ? ('00000' + res.data['nr']).slice(-5) : '00001';
        // if (reg) {
        //     return `${invoiceType}-${reg}-${nr}-${type}-${new Date().toISOString().slice(0, 4)}`
        // } else {
        //     return `${invoiceType}-${nr}-${type}-${new Date().toISOString().slice(0, 4)}`
        // }
    },
    async createDocSellNr(id, type, invoiceType, reg) {
        return await this.createDocNrFor(id, type, invoiceType, reg, "/sell-invoice/next")
    },

    vatCalculate(neto, vatRate) {
        return {
            vat_base: neto,
            vat: (
                Math.round(((neto * vatRate) / 100) * 100) /
                100
            ).toFixed(2),
            amount: (
                Math.round(neto * (1 + vatRate / 100) * 100) /
                100
            ).toFixed(2),
        };
    },
    toNumberFormat(val) {
        return val.toLocaleString("sr-RS", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    },
    showDialog(data, message, title, action, noAction) {
        store.commit("app/showModal", {
            modal: ConfirmDialog,
            params: {
                message,
                title,
                onYes: (data) => action(data),
                onNo: noAction ? (data) => noAction(data): this.confirmNo,
                noClose: !!noAction
            },
        });
    },
    confirmNo() {
        store.commit("app/hideModal");
    },
    showAlert(type, message) {
        store.commit("app/showAlert", {
            type,
            message,
        });
    },
    typeName(type, mod = 1) {
        let res = type == 'Proform' ? 'Predračun' : type == 'Normal' ? 'Račun' : 'Avansni račun';
        switch (mod) {
            case 1:
                res = type == 'Proform' ? 'Predračun' : type == 'Normal' ? 'Račun' : 'Avansni račun';
                break;
            case 2:
                res = type == 'Proform' ? 'Predračuni' : type == 'Normal' ? 'Računi' : 'Avansni računi';
                break
            default:
                break;
        }
        return res;

    },
    arrSum(arr, firstItem, secItem, additionaItem) {
        var sum = 0;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] && arr[i][firstItem]) {
                for (var j = 0; j < arr[i][firstItem].length; j++) {
                    if (additionaItem) {
                        sum += Number.parseFloat(arr[i][firstItem][j][secItem]) + Number.parseFloat(arr[i][firstItem][j][additionaItem]);
                    } else {
                        sum += Number.parseFloat(arr[i][firstItem][j][secItem]);
                    }
                }
            }
        }
        return sum.toLocaleString('sr', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    snakeToCamel(str) {
        return str.toLowerCase().replace(/([-_][a-z])/g, group =>
            group
                .toUpperCase()
                .replace('-', '')
                .replace('_', '')
        );
    },
    snakeToTitle(str) {
        return str.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase());
    },
    additionalLabel(str) {
        const labels = {
            contract_nr: { type: "text", label: "Broj ugovora o najmu", 'order': 1 },
            km: {
                type: "number", label: "Predjeno KM", 'order': 2,
            },
            driver: {
                type: "text", label: "Vozač",   'order': 3,
            },
            pickup_date: {
                type: "date", label: "Datum preuzimanja", 'order': 4,
            },
            
            return_date: {
                type: "date", label: "Datum vraćanja", 'order': 5,
            },
            registration_nr: {
                type: "text", label: "Registarski broj", 'order': 6,
            },
            model: {
                type: "text", label: "Brend/Model vozila", 'order': 7,
            },
            its_no: {
                type: "text", label: 'ITS No.', 'order': 8,
            },
            incoming_invoice: {
                type: "text", label: 'Broj ulazne fakture', 'order': 9,
            },
            wheels_nr: {
                type: "text", label: 'Wheels broj računa', 'order': 10,
            },
        }
        return labels[str];
    },
    setDataFromRac(response, data, setPrice = false) {
        // console.log({'res':response.data, data});
        data['brand'] = response.data[4];
        data['model'] = `${response.data[5]} ${response.data[6]}`;
        data['registration_nr'] = response.data[8];
        data['chassis_nr'] = response.data[9];
        data['color'] = response.data[17];
        data['year'] = Number(response.data[18]);
        data['contract_nr'] = response.data[28];
        
        /// convert string dd/mm/yyyy to date
        data['first_registration'] = response.data[62] ? new Date(response.data[62].split("/").reverse().join("-")) : null;
        data['engine_nr'] = response.data[26];
        data['engine_power'] = Number(response.data[20].replaceAll('.', '').replaceAll(',', '.'));
        data['engine_volume'] = Number(response.data[22].replaceAll('.', '').replaceAll(',', '.'));
        data['seats'] = Number(response.data[24]);
        data['mass'] = Number(response.data[25].replaceAll('.', '').replaceAll(',', '.'));
        data['km'] = Number(response.data[53].replaceAll('.', '').replaceAll(',', '.')) == 0 ? null : Number(response.data[53].replaceAll('.', '').replaceAll(',', '.'));
        // for cession only
        if (setPrice) {
            // console.log(response.data[43]);
            data['bb_neto_eur'] = Number(response.data[43].replaceAll('.', '').replaceAll(',', '.'));
        }
      },
}