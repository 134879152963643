<template>
  <Decision v-if="$route.params.type == 'o'" :content="content" />
  <Contract
    v-else-if="$route.params.type == 'u' && $route.params.doc != 'prod'"
    :content="content"
  />
  <Offer v-else-if="$route.params.type == 'po'" :content="content" />
  <ContractProd
    v-else-if="
      ($route.params.type == 'u' || $route.params.type == 'pu') &&
      $route.params.doc == 'prod'
    "
    :content="content"
  />
  <Minutes v-else-if="$route.params.type == 'm'" :content="content" />
  <MinutesDamage v-else-if="$route.params.type == 'mo'" :content="content" />
  <InvoiceProd
    v-else-if="
      ($route.params.type == 'r' ||
        $route.params.type == 'sr' ||
        $route.params.type == 'ir' ||
        $route.params.type == 'pr') &&
      $route.params.doc == 'prod'
    "
    :content="content"
  />
  <Invoice v-else :content="content" />
</template>

<script>
import CessionsDataService from "../../services/cessions.service";
import SellingsDataService from "../../services/sellings.service";
import Invoice from "./invoice.vue";
import InvoiceProd from "./invoice-prod.vue";
import Decision from "./decision.vue";
import Contract from "./contract.vue";
import ContractProd from "./contract-prod.vue";
import Minutes from "./minutes.vue";
import MinutesDamage from "./minutes-damage.vue";
import Offer from "./offer.vue";
export default {
  name: "report",
  components: {
    Invoice,
    InvoiceProd,
    Decision,
    Contract,
    ContractProd,
    Offer,
    Minutes,
    MinutesDamage,
  },
  props: [],
  methods: {},
  data() {
    return {
      content: {},
    };
  },
  computed: {
    // currentUser() {
    //   return this.$store.state.auth.user;
    // },
  },
  mounted() {
    if (this.$route.params.doc == "ces") {
      CessionsDataService.get(this.$route.params.id).then(
        (response) => {
          this.content = response.data;
          let title = `${this.$route.params.doc}-${this.$route.params.type}-${response.data.registration_nr}`;
          /// type o - odluka, u - ugovor, po - ponuda, m - zapisnik, mo - zapisnik o ostecenju, r - racun, sr - storniran racun, ir - interni racun, pr - predracun, spr - storniran predracun
          if (["r", "sr", "ir", "pr", "spr"].includes( this.$route.params.type ) ) {
            const invoice = response.data.invoices.find(
              (i) => i.id == this.$route.params.docid
            );
            title = `${this.$route.params.doc}-${this.$route.params.type.length == 2 ? this.$route.params.type.charAt(0) : ''}${invoice ? invoice.doc_nr : ''}`;
          }
          // const title = `${response.data && response.data.invoices ? response.data.invoices[0].doc_nr : `ces-${this.$route.params.type}`} `;
          setTimeout(function () {
            window.document.close();
            window.document.title = `${title.toUpperCase()} `;
            window.focus();
            window.print();
            window.onafterprint = window.close();
          }, 300);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    } else if (this.$route.params.doc == "prod") {
      SellingsDataService.get(this.$route.params.id).then(
        (response) => {
          let title = `${this.$route.params.doc}-${this.$route.params.type}-${response.data.registration_nr}`;
          this.content = response.data;
          /// type o - odluka, u - ugovor, po - ponuda, m - zapisnik, mo - zapisnik o ostecenju, r - racun, sr - storniran racun, ir - interni racun, pr - predracun, spr - storniran predracun
          if (["r", "sr", "ir", "pr", "spr"].includes( this.$route.params.type ) ) {
            const invoice = response.data.invoices.find(
              (i) => i.id == this.$route.params.docid
            );
            title = `${this.$route.params.doc}-${this.$route.params.type.length == 2 ? this.$route.params.type.charAt(0) : ''}${invoice ? invoice.doc_nr : ''}`;
          }
          setTimeout(function () {
            window.document.close();
            window.document.title = `${title.toUpperCase()} `;
            window.focus();
            window.print();
            window.onafterprint = window.close();
          }, 300);
        },
        (error) => {
          console.error(error);
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
};
</script>