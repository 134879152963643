<template>
  <div class="col border-top small" v-if="type == 'pr'">
    PREDRAČUN BROJ: P{{ invoiceData ? invoiceData.doc_nr : "" }}
    <br />
    Datum predračuna:
    {{ invoiceData ? dateFormat(invoiceData.storno_date ?? invoiceData.doc_date) : "" }}<br />
    Mestoizdavanja predračuna: Beograd <br />
    Valuta plaćanja:
    {{ invoiceData ? dateFormat(invoiceData.val_date) : "" }}<br />
  </div>
  <div class="col border-top small" v-else>
    {{ type == "r" || type == "ir" ? "RAČUN BROJ" : type == "spr" ? "SPECIFIKACIJA RAČUNA BROJ" : "STORNO-RAČUN" }}:
    {{ type == "sr" ? "S" : "" }}{{ type == "ir" ? "I" : ""
    }}{{ invoiceData ? invoiceData.doc_nr : "" }}
    <br />
    Mesto i datum izdavanja {{type == "spr" ? 'specifikacije' : 'računa'}}: Beograd
    {{ invoiceData ? dateFormat(invoiceData.storno_date ?? invoiceData.doc_date) : "" }}<br />
    Mesto i datum prometa dobara: Beograd
    {{ invoiceData ? dateFormat(invoiceData.storno_date ?? invoiceData.doc_date) : "" }}<br />
    Valuta plaćanja:
    {{ invoiceData ? dateFormat(invoiceData.val_date) : "" }}<br />
  </div>
</template>

<script>
import dateUtils from "../../utils/date-common";
export default {
  name: "report_header",
  components: {},
  props: ["content", "type"],
  methods: {
    dateFormat(date) {
      return dateUtils.formatDate(date);
    },
  },
  data() {},
  computed: {
    invoiceData() {
      if (this.$route.params.docid) {
          return this.content["invoices"]
            ? this.content["invoices"].find(
                (pf) => pf.id == this.$route.params.docid
              )
            : null;
        } else {
          return this.content["invoices"] ? this.content["invoices"][0] : null;
        }
    },
  },
};
</script>