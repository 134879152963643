<template>
  <div v-if="content" class="container">
    <div class="row">
      <div class="col small">
        Na osnovu člana 436. do 445. Zakona o obligacionim poslovima, zaključuje
        se
      </div>
    </div>
    <div class="row">
      <div class="col small text-center">
        <h4>
          {{ $route.params.type == "pu" ? "PRED" : "" }}UGOVOR O PRODAJI I
          KUPOVINI POLOVNOG MOTORNOG VOZILA<br />br.
          {{ content.doc_nr ? content.doc_nr.replace("PO-", "U-") : "" }}
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        zaključen dana
        {{
          content.invoices
            ? getInvoiceDate
            : ""
        }}
        godine u Beogradu između:
      </div>
    </div>
    <div class="row">
      <div class="col-7 small">
        Prodavac:<br />
        <div class="border-top border-bottom small">
          {{ seller.name }}<br />
          {{ seller.addr }}<br />
          PIB: {{ seller.pib }}<br />
          Matični broj: {{ seller.mb }}<br />
          Tekući račun: {{ seller.tr2 }}<br />
          Tel: {{ seller.tel }}
        </div>
      </div>
      <div class="col-5 small">
        Kupac:
        <div class="border-top border-bottom small">
          {{ content.Buyer ? content.Buyer.name : "" }}<br />
          {{ content.Buyer ? content.Buyer.address : "" }}<br />
          {{ content.Buyer ? content.Buyer.zip : "" }}
          {{ content.Buyer ? content.Buyer.city : "" }},
          {{ content.Buyer ? content.Buyer.country : "" }}<br />
          PIB: {{ content.Buyer ? content.Buyer.pib : "" }}<br />
          Matični broj: {{ content.Buyer ? content.Buyer.mb : "" }} <br />
          Tekući račun: {{ content.Buyer ? content.Buyer.bank_account : "" }}<br />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        Predmet prodaje i kupovine je
        {{ content.article ? content.article.name : "" }} sa sledećim podacima i
        karakteristikama:
      </div>
    </div>
    <div class="row border-top border-bottom">
      <div class="col-3 small">
        Marka vozila: <br />
        Tip vozila: <br />
        Broj registracije: <br />
        Broj šasije vozila: <br />
        Broj motora vozila: <br />
        Godina proizvodnje: <br />
      </div>
      <div class="col-3 small fw-bold">
        {{ content.brand }}<br />
        {{ content.model }}<br />
        {{ content.registration_nr }}<br />
        {{ content.chassis_nr }}<br />
        {{ content.engine_nr }}<br />
        {{ content.year }}<br />
      </div>
      <div class="col-3 small">
        Prva registracija:<br />
        Snaga motora (KW):<br />
        Radna zapremina (ccm):<br />
        Broj sedišta: <br />
        Boja karoserije:<br />
        Pređena kilometraža (KM):<br />
      </div>
      <div class="col-3 small fw-bold">
        {{ dateFormat(content.first_registration) }}<br />
        {{ content.engine_power }}<br />
        {{ content.engine_volume }}<br />
        {{ content.seats }}<br />
        {{ content.color }}<br />
        {{ content.km }}<br />
      </div>
    </div>
    <div class="row">
      <div class="col small">
        Ugovorene strane su se sporazumele o sledećem:
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 1.</div>
        <div class="small">
          Prodavac prodaje u viđenom stanju i obavezuje se da kupcu preda u svojinu i državinu motorno vozilo opisano u predmetu prodaje i kupovine sa odgovarajućim ispravama prema ponudi i primopredajnom zapisniku i bez skrivenih nedostataka.
          <br>Prodavac garantuje Kupcu da predmetno vozilo nije pribavljeno krivičnim delom ili na ma koji drugi nelegalan način, kao i da na njemu ne postoje bilo kakvi tereti i/ili prava trećih lica.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 2.</div>
        <div class="small" v-if="content.damages">
          Prodavac izjavljuje da su svi sklopovi, sistemi i oprema na vozilu 
          pretrpela totlnu štetu što potvrđuje ponuda za vozilo i primopredajni zapisnik
          koji su sastavni deo ovog ugovora
        </div>
        <div class="small" v-else>
          Prodavac izjavljuje da su svi sklopovi, sistemi i oprema na vozilu
          ispravni i u funkcionalnom stanju i da nema skrivenih nedostataka što
          potvrđuje ponuda za vozilo i primopredajni zapisnik koji su sastavni
          deo ovog ugovora.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 3.</div>
        <div class="small">
          Prodavac prilaže "zapisnik o oštećenjima vozila" i kopije zapisnika
          Osiguravajućeg Zavoda o nastalim i popravljenim oštećenjima kao dokaz
          o pretrpljenim havarijskim oštećenjima na vozilu.<br>
          Kupac potpisom na ovom Ugovoru izričito i neopozivo potvrđuje da je upoznat sa navedenom dokumentacijom, kao i da je upoznat sa tim koje su popravke i na koji način vršene na samom vozilu, te prihvata da takvo vozilo kupi od Prodavca.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 4.</div>
        <div class="small">
          Prodavac izjavljuje da je vozilo bilo upotrebljavano kao službeno
          vozilo.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 5.</div>
        <div class="small" v-if="content.damages">
          Prodavac daje akciju na sledeće: Cena se usled konstatovane totalne štete utvrđuje na {{ content.id ? numFormat(pricesRsd.amount ?? 0) : 0 }} RSD sa PDV-om.
        </div>
        <div class="small" v-else>Prodavac daje akciju na sledeće: Nema akcija.</div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 6.</div>
        <div class="small">
          Kupac preuzima motorno vozilo navedeno u predmetu prodaje i kupovine po ponudi i primopredajnom zapisniku uz prateću dokumentaciju koja čini sastavni deo ovog ugovora. Prodavac se obavezuje da Kupcu preda svu prateću dokumentaciju zajedno sa predajom vozila, a sve nakon što Kupac isplati celokupnu kupoprodajnu cenu po izdatom računu.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 7.</div>
        <div class="small">
          Kupac svojeručno potpisuje Ugovor o prodaji i kupovini polovnog motornog vozila čime potvrđuje da je upoznat i saglasan sa odredbama i članovima ugovora, svim detaljima ponude i ugovora i prihvata vozilo prema primopredajnom zapisniku u stanju u kojem ga prodavac prodaje po ugovorenoj ceni i uslovima, tj. vozilo kupuje u viđenom stanju. 
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 8.</div>
        <div class="small">
          Prodavac snosi odgovornost za nesaobraznosti koje nastanu u roku od jedne godine od dana prelaska rizika na Kupca.,<br>
          Radi otklanjanja bilo kakvih nedoumica, odgovornost za nesaobraznost se primenjuje samo u slučaju ukoliko je Kupac fizičko lice.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 9.</div>
        <!-- <div class="small">
          Kupac kupuje motorno vozilo navedeno u predmetu prodaje i kupovine i
          obavezuje se da prodavcu isplati prodajnu cenu u iznosu od {{ content.id ? numFormat(prices.amount ?? 0) : 0 }} {{ (content.exchange ?? '').toUpperCase() }}. 
          Navedeni iznos će kupac isplatiti prodavcu u roku koji je
          naznačen u ponudi.<br>
          Ugovarači saglasno konstatuju da je ugovorena kupoprodajna cena utvrđena nakon što je Kupac izvršio procenu stanja vozila, a sve na osnovu uvida u servisni istorijat vozila, opšteg stanja vozila, eventualnog postojanja oštećenja, ogrebotina ili havarija, pređene kilometraže, tržišne cene vozila, kao i potražnje na tržištu za vozilom koje je predmet kupoprodaje.
        </div> -->
        <div class="small">
          Kupac kupuje motorno vozilo navedeno u predmetu prodaje/kupovine i obavezuje se da prodavcu isplati prodajnu cenu u ukupnom iznosu od {{ content.id ? numFormat(prices.amount ?? 0) : 0 }} {{ (content.exchange ?? '').toUpperCase() }} sa uračunatim PDV-om u dinarskoj protivvrednosti po srednjem kursu NBS na dan plaćanja. Navedeni iznos će kupac isplatiti prodavcu u roku koji je naznačen u ponudi.<br>
          Ugovarači saglasno konstatuju da je ugovorena kupoprodajna cena utvrđena nakon što je Kupac izvršio procenu stanja vozila, a sve na osnovu uvida u servisni istorijat vozila, opšteg stanja vozila, eventualnog postojanja oštećenja, ogrebotina ili havarija, pređene kilometraže, tržišne cene vozila, kao i potražnje na tržištu za vozilom koje je predmet kupoprodaje.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 10.</div>
        <div class="small">
          Prodavac se obavezuje da kupcu preda motorno vozilo po isplati
          ugovorene cene sa rokom isporuke od 10 radnih dana.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 11.</div>
        <div class="small">
          Kupac je dužan da u zakonskom roku prenese vlasništvo vozila na svoje ime. U slučaju da prenos vlasništva, registraciju ili druge administrativne poslove u vezi vozila nije moguće izvršiti u zakonskom roku usled nastanka više sile ili uvođenja vanrednog stanja u državi, kupac je dužan da prenese vlasništvo na svoje ime čim se za to steknu uslovi.
          <br>Svi troškovi koji mogu nastati od momenta kada klijent isplati prodajnu cenu i/ili preuzme vozilo odnosno upotrebom vozila padaju na teret kupca. Kupac potpisom ovog ugovora potvrđuje da je svestan situacije i da neće tražiti umanjenje cene ili drugu vrstu nadoknade zbog problema sa odjavom, registracijom ili drugih troškova odnosno nemogućnosti korišćenja vozila usled nastalih okolnosti.
          <br>Radi otklanjanja bilo kakvih nedoumica, Kupac snosi sve poreske obaveze po ovom Ugovoru, kao i troškove preregistracije vozila.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 12.</div>
        <div class="small">
          Ugovor prestaje da važi ako vozilo propadne usled slučaja više sile
          pre izvršenja ugovora.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 13.</div>
        <div class="small">
          Prigovor i reklamacije klijent može podneti pismenim putem u prodajnom
          objektu u kome je vozilo i kupljeno, odnosno na adresi sedišta
          kompanije. Prodavac će odlučiti o prigovoru u zakonskom roku od 8
          dana.<br>
          Zaključenjem ovog Ugovora prestaju da važe svi usmeni i/ili pisani dogovori koje su ugovarači postigli pre zaključenja ovog ugovora, a koji dogovori se odnose na predmet ovog ugovora, osim ukoliko su ti dogovori sadržani u aneksu ovog ugovora.
          <br>Ovaj ugovor je sačinjen u 2 (dva) istovetna primerka, od kojih po 1 (jedan) primerak zadržava svaki ugovarač. 
          <br>Ovaj Ugovor stupa na snagu danom potpisivanja od strane ovlašćenih predstavnika oba ugovarača. Ugovarači saglasno konstatuju da su ugovor pročitali, da su u potpunosti razumeli sve njegove odredbe te ga u znak prihvatanja potpisuju.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <div class="text-center fw-bold">Član 14.</div>
        <div class="small">
          Eventualni spor u vezi sa ovim ugovorom rešavaće stvarno nadležan sud  prema mestu sedišta Prodavca.
        </div>
      </div>
    </div>

    <div class="row pt-5">
      <div class="col text-center small">
        PRODAVAC<br /><br />
        _______________________<br />
        {{ seller.name }}
      </div>
      <div class="col text-center small"></div>
      <div class="col text-center small">
        KUPAC<br /><br />
        _______________________<br />
        {{ content.Buyer ? content.Buyer.name : "" }}
      </div>
    </div>
  </div>
</template>

<script>
import dateUtils from "../../utils/date-common";
import utils from "../../utils/common";
// import Foother from "./foother.vue";
export default {
  name: "contract-prod",
  components: {
    // Foother,
  },
  props: ["content"],
  methods: {
    dateFormat(date) {
      return dateUtils.formatDate(date);
    },
    numFormat(num) {
      return utils.toNumberFormat(Number(num));
    },
  },
  data() {
    // return {
    //   content: {},
    // };
  },
  computed: {
     taxRates() {
      return this.$store.state.app.taxRates;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    seller() {
      return this.$store.state.app.seller;
    },
    getInvoiceDate() {
      /// return date from first invoice with type Normal and status Done
      if (!this.content.invoices) return "";
      // console.log(this.content.invoices)
      const normalDoneInvoice = this.content.invoices.find(
        (i) => i.invoice_type == "Normal" && i.invoice_status == "Done"
      );
      /// if getInvoiceDate is null try to find New invoice
      if (!normalDoneInvoice) {
        const normalNewInvoice = this.content.invoices.find(
          (i) => i.invoice_type == "Normal" && i.invoice_status == "New"
        );
        return normalNewInvoice ? dateUtils.formatDate(normalNewInvoice.doc_date) : "__.__.____";
      }
      return normalDoneInvoice ? dateUtils.formatDate(normalDoneInvoice.doc_date) : "__.__.____";
    },
    prices() {
      const vatRate = this.taxRates.find((t) => t.id == this.content.article.tax);
      const base = this.content["bb_neto_eur"];
      const vats = utils.vatCalculate(base, vatRate.rate);
      // console.log({ vats });
      return {
        vat_base: Number(base),
        vat: Number(vats.vat),
        amount: Number(vats.amount),
        rate: Number(vatRate),
      };
    },
    pricesRsd() {
      const vatRate = this.taxRates.find((t) => t.id == this.content.article.tax);
      const base = (this.content["bb_neto_eur"] * this.content["exchange_value"]);
      const vats = utils.vatCalculate(base, vatRate.rate);
      // console.log({ vats });
      return {
        vat_base: Number(base),
        vat: Number(vats.vat),
        amount: Number(vats.amount),
        rate: Number(vatRate),
      };
    },
  },
};
</script>

<style scoped>
.container {
  /* border: 1px solid gray; */
  width: 800px;
  height: 900px;
}
.container .row > .col,
.container .row > [class^="col-"] {
  padding-top: 0.75rem;
  /* padding-bottom: 0.75rem; */
  /* background-color: rgba(39, 41, 43, 0.03); */
  /* border: 1px solid rgba(39, 41, 43, 0.1); */
}

.logo {
  padding: 0 !important;
}

img {
  height: 120px;
}

.sig {
  float: right;
}
tr {
  vertical-align: middle;
}
.gray {
  background: lightgray;
}
</style>