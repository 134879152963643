<template>
  <div class="row md-3" v-if="['pr','r','sr', 'spr', 'po', 'ir'].includes(type)">
    <div class="col"></div>
    <div class="col"></div>
    <div class="col">
      <div class="p-3 sig text-center small">
        Dokument izradio/la<br />
        {{ currentUser.email }} <br />
        {{ currentUser.firstname }} {{ currentUser.lastname }}
      </div>
    </div>
  </div>
  <div class="row md-3 pt-5 mt-5" v-else-if="type == 'o'">
    <div class="col">
      <div class="p-3 sig text-center small">U Beogradu, {{ date }}</div>
    </div>
    <div class="col"></div>
    <div class="col">
      <div class="p-3 sig text-center small">
        {{ seller.name }}<br /><br />
        _______________________<br />
        {{ seller.authorizedPerson }} <br />
        {{ seller.position }}
      </div>

    </div>
  </div>
  <div class="row md-3" v-else>
    <div class="col">
      <div class="p-3 sig text-center small">
        Fakturisao/la<br />
        {{ currentUser.email }} <br />
        {{ currentUser.firstname }} {{ currentUser.lastname }}
      </div>
    </div>
    <div class="col">
      <div class="p-3 sig text-center small">
        {{ type == "sr" ? "Storno-" : "" }}Račun izradio/la<br />
        {{ currentUser.email }} <br />
        {{ currentUser.firstname }} {{ currentUser.lastname }}
      </div>
    </div>
    <div class="col">
      <div class="p-3 sig text-center small">
        Račun preuzeo/la<br />
        <br />
        ______________________________________
      </div>
    </div>
  </div>
</template>

<script>
import dateUtils from "../../utils/date-common";
export default {
  name: "report_foother",
  components: {},
  props: ["type", "date"],
  methods: {
    dateFormat(date) {
      return dateUtils.formatDate(date);
    },
  },
  data() {},
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    seller() {
      return this.$store.state.app.seller;
    },
  },
  mounted() {},
};
</script>