export default {
    formatDate(date) {
        if (!date) return null;
        const dat = new Date(date)
        return dat.toLocaleDateString('de-DE', { dateStyle: 'medium' });
    },
    formatDateTime(date) {
        if (!date) return null;
        const dat = new Date(date)
        return dat.toLocaleString('de-DE');
    },
    getFirstDayPreviousMonth() {
        const date = new Date();
        const prevMonth = date.getMonth() - 1;
        const firstDay = 1;

        return new Date(date.getFullYear(), prevMonth, firstDay);
    },
    getFirstDayOfsMonth() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    },
    toMysqlDate(date) {
        if (!date) return null;
        const dat = new Date(date)
        return dat.toISOString().slice(0, 10);
    }
}