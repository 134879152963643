<template>
  <div class="container">
    <div class="row">
      <div class="col">
        {{ message }}
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="actions" v-if="!noActions">
          <button
            type="button"
            class="btn btn-success "
            :class="{ 'bi bi-check': !load,}"
            :disabled="load"
            @click="load=true; $emit('yes')"
          >
            <span v-if="load" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            DA
          </button>
          <button
            type="button"
            class="btn btn-danger bi bi-x"
            @click="$emit('no')"
          >
            NE
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirm-dialog",
  props: ["message", "noActions"],
  methods: {},
  data() {
    return {load: false,}
  }, 
  mounted() {
    this.load = false;
  }
};
</script>

<style scoped>
</style>