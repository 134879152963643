<template>
  <div v-if="content" class="container">
    <div class="row row-cols-2">
      <div class="col logo">
        <img
          src="/images/logo_2.jpg"
          alt="Ifero 2.0"
          class="d-inline-block align-text-top"
        />
      </div>
      <div class="col small">
        datum:
        {{ content.invoices
            ? getInvoiceDate
            : content.sell_form ? dateFormat(content.sell_form.createdAt) : "" }}
      </div>
    </div>
    <div class="row p-2">
      <div class="col small text-center">
        <h3>
          Zapisnik o oštećenjima na vozilu po ugovoru br.
          {{ content.doc_nr ? content.doc_nr.replace("PO-", "U-") : "" }}
        </h3>
      </div>
    </div>
    <div class="row p-2">
      <div class="col small">
        Prema članu 3. ugovora o prodaji i kupovini polovnog motornog vozila,
        prodavac prilaže kopije zapisnika šteta osiguravajućeg zavoda o nastalim
        i popravljenim oštećenjima kao dokaz o pretrpljenim havarijskim
        oštećenjima na vozilu
      </div>
    </div>

    <div class="row">
      <div class="col small">
        <div class="small fw-bold fs-5">
          Štete prema zapisniku osiguravajućeg zavoda
        </div>
      </div>
    </div>
    <div class="row p-2">
      <!-- <div class="col small"> -->
      <pre>{{ content.sell_form && content.sell_form.damages_insurance }}</pre>
      <!-- </div> -->
    </div>
    <div class="row">
      <div class="col small">
        <div class="small fw-bold fs-5">
          Opis postojećih oštećenja na vozilu
        </div>
      </div>
    </div>
    <div class="row p-2">
      <!-- <div class="col small"> -->
      <pre>{{ content.sell_form && content.sell_form.damages }}</pre>
      <!-- </div> -->
    </div>
    <div class="row">
      <div class="col small">
        <div class="small fw-bold fs-5">Napomena</div>
      </div>
    </div>
    <div class="row p-2">
      <!-- <div class="col small"> -->
      <pre>{{ content.sell_form && content.sell_form.comment }}</pre>
      <!-- </div> -->
    </div>

    <div class="row pt-5">
      <div class="col text-center small">
        PRODAVAC<br /><br />
        _______________________<br />
        {{ seller.name }}
      </div>
      <div class="col text-center small"></div>
      <div class="col text-center small">
        KUPAC<br /><br />
        _______________________<br />
        {{ content.Buyer ? content.Buyer.name : "" }}
      </div>
    </div>
  </div>
</template>

<script>
import dateUtils from "../../utils/date-common";
import utils from "../../utils/common";
// import Foother from "./foother.vue";
export default {
  name: "minutes-damage",
  components: {
    // Foother,
  },
  props: ["content"],
  methods: {
    dateFormat(date) {
      return dateUtils.formatDate(date);
    },
    numFormat(num) {
      return utils.toNumberFormat(num);
    },
  },
  data() {
    // return {
    //   content: {},
    // };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    seller() {
      return this.$store.state.app.seller;
    },
    getInvoiceDate() {
      /// return date from first invoice with type Normal and status Done
      if (!this.content.invoices) return "";
      // console.log(this.content.invoices)
      const normalDoneInvoice = this.content.invoices.find(
        (i) => i.invoice_type == "Normal" && i.invoice_status == "Done"
      );
      /// if getInvoiceDate is null try to find New invoice
      if (!normalDoneInvoice) {
        const normalNewInvoice = this.content.invoices.find(
          (i) => i.invoice_type == "Normal" && i.invoice_status == "New"
        );
        return normalNewInvoice ? dateUtils.formatDate(normalNewInvoice.doc_date) : "__.__.____";
      }
      return normalDoneInvoice ? dateUtils.formatDate(normalDoneInvoice.doc_date) : "__.__.____";
    },
  },
};
</script>

<style scoped>
.container {
  /* border: 1px solid gray; */
  width: 800px;
  height: 900px;
}
.container .row > .col,
.container .row > [class^="col-"] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  /* background-color: rgba(39, 41, 43, 0.03); */
  /* border: 1px solid rgba(39, 41, 43, 0.1); */
}

.logo {
  padding: 0 !important;
}

img {
  width: 240px;
}

.sig {
  float: right;
}
tr {
  vertical-align: middle;
}
.gray {
  background: lightgray;
}
pre {
    white-space: pre-wrap;
}
</style>